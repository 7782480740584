<template>
  <div id="app" class="url" style="padding: 15px">
    <el-row>
      <el-col :span="24">
        <el-page-header @back="goBack" content="权限类型管理"> </el-page-header>
      </el-col>
    </el-row>

    <el-row :gutter="20">
      <el-button
        icon="el-icon-plus"
        type="success"
        @click="dialogFormVisible = true"
        class="add"
        >添加</el-button
      >
    </el-row>

    <el-dialog title="添加信息" :visible.sync="dialogFormVisible" width="35%" :close-on-click-modal="false">
      <el-form :model="form">
        <el-form-item label="权限类型名称">
          <el-input
            v-model="form.info"
            autocomplete="off"
            style="width: 80%"
          ></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="appendInfo">确 定</el-button>
      </div>
    </el-dialog>

    <el-table
      ref="singleTable"
      :data="permissionTypeList"
      highlight-current-row
      style="width: 100%"
      height="400"
    >
      <el-table-column
        type="index"
        label="序号"
        width="100"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="smallSystemName"
        property="date"
        label="权限类型名称"
        align="center"
      >
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="100" align="center">
        <template slot-scope="scope">
          <el-button
            @click="deletPerType(scope.row.id)"
            type="danger"
            size="small"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import jurisSmallSystem from "@/api/managementApi/PermissionTypeManage";

export default {
  data() {
    return {
      dialogFormVisible: false, // 添加弹框 默认隐藏

      form: {
        info: "",
      },
      // permissionType:{      // 获取添加的权限类型
      //   info:''
      // },
      permissionTypeList: [],
      tableData: [
        {
          date: "访客",
        },
      ],
    };
  },
  created() {
    this.getPermissionTypeList();
  },

  methods: {
    // 返回上一级
    goBack() {
      this.$router.push("/Select");
    },

    // 获取权限类型信息列表
    getPermissionTypeList() {
      jurisSmallSystem.getJurisSmallSystem().then((res) => {
        console.log(res);
        this.permissionTypeList = res.data.data.list;
      });
    },

    // 添加 权限类型
    appendInfo() {
      if (this.form.info != "") {
        jurisSmallSystem.addJurisSmallSystem(this.form.info).then((res) => {
          // console.log(res);
          this.dialogFormVisible = false;
          this.form.info = '';
          this.getPermissionTypeList();
        });
      } else {
        this.$message.error("权限类型不能为空！");
      }
    },

    // 删除权限类型
    deletPerType(id) {
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        //点击确定，指定then方法
        jurisSmallSystem.deleteJurisSmallSystem(id).then((res) => {
          //提示信息
          this.$message({
            type: res.data.success ? "success" : "warning",
            message: res.data.message,
          });
          //回到页面并刷新
          this.getPermissionTypeList();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add {
  margin: 10px 10px;
}
</style>